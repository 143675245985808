import ObjVr from "../../../shared/models/obj_vr.js";
import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

const state = {
  modelClass: ObjVr,
  baseUrl: "/api/web/member/obj_vrs",
  resources: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
